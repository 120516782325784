// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import Layout from './components/Layout';
import Auth from './components/Auth';
import ProfileForm from './components/ProfileForm';
import Chatbot from './pages/Chatbot';
import Friends from './pages/Friends';
import Profile from './pages/Profile';
import PostDetailView from './components/PostDetailView';
import { initAuthListener } from './authManager';
import NotificationHandler from './components/NotificationHandler';
import { initializeFCM, refreshTokenOnInit } from './services/fcmTokenService';
import { ChatProvider } from './contexts/ChatContext';
import { PostProvider } from './contexts/PostContext';
import { isNotificationSupported } from './utils/featureDetection';
import Button from './components/Button';
import './App.css';
import logo from './assets/logo.png';

const LoadingScreen = () => (
  <div className="loading-screen">
    <img src={logo} alt="App Logo" className="loading-logo" />
  </div>
);

const ErrorScreen = ({ message }) => (
  <div className="error-screen">
    <p>{message}</p>
    <Button onClick={() => window.location.reload()} variant="primary" size="medium">
      Retry
    </Button>
  </div>
);

function App() {
  const [user, loading] = useAuthState(auth);
  const { t, i18n } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [profileStatus, setProfileStatus] = useState('loading');
  const [notificationSupported, setNotificationSupported] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState('default');
  const [showPushNotificationPrompt, setShowPushNotificationPrompt] = useState(true);

  useEffect(() => {
    console.log('App started');
    initAuthListener();

    const initializeApp = async () => {
      console.log('Initializing app');
      if (isNotificationSupported()) {
        setNotificationSupported(true);
        setNotificationPermission(Notification.permission);
        const fcmInitialized = await initializeFCM();
        console.log('FCM initialization completed', fcmInitialized ? 'successfully' : 'with warnings');
      } else {
        console.log('Notifications are not supported on this browser');
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    const fetchProfileAndVerifyToken = async () => {
      if (user) {
        console.log('Auth state changed', { userId: user.uid });
        try {
          if (notificationSupported) {
            await refreshTokenOnInit();
          }
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const profileData = docSnap.data();
            setProfile(profileData);
            if (profileData.displayName && profileData.username) {
              setProfileStatus('complete');
            } else {
              setProfileStatus('incomplete');
            }

            // Set language based on user preference
            if (profileData.languagePreference) {
              i18n.changeLanguage(profileData.languagePreference);
            }
          } else {
            setProfile({ phoneNumber: user.phoneNumber || '' });
            setProfileStatus('incomplete');
          }
        } catch (error) {
          console.error('Error fetching profile or refreshing token:', error);
          setProfileStatus('error');
        }
      } else {
        console.log('Auth state changed', { user: null });
        setProfile(null);
        setProfileStatus('incomplete');
      }
    };

    if (user && profileStatus === 'loading') {
      fetchProfileAndVerifyToken();
    } else if (!loading && !user) {
      setProfileStatus('incomplete');
    }
  }, [user, notificationSupported, loading, profileStatus, i18n]);

  useEffect(() => {
    if (notificationSupported) {
      const checkNotificationPermission = () => {
        setNotificationPermission(Notification.permission);
      };

      checkNotificationPermission();
      window.addEventListener('focus', checkNotificationPermission);

      return () => {
        window.removeEventListener('focus', checkNotificationPermission);
      };
    }
  }, [notificationSupported]);

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(prevProfile => ({ ...prevProfile, ...updatedProfile }));
    setProfileStatus('complete');
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingScreen />;
    }

    if (!user) {
      return <Auth onAuthSuccess={() => setProfileStatus('loading')} />;
    }

    switch (profileStatus) {
      case 'loading':
        return <LoadingScreen />;
      case 'incomplete':
        return <ProfileForm 
          existingProfile={profile} 
          onProfileUpdate={handleProfileUpdate}
        />;
      case 'complete':
        return (
          <PostProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<Chatbot />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/:userId?" element={<Profile />} />
                <Route path="/profile/:userId?/saved" element={<Profile initialTab="saved" />} />
                <Route 
                  path="/post/:postId" 
                  element={<PostDetailView key={window.location.pathname} />} 
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              {notificationSupported && <NotificationHandler />}
              {notificationSupported && notificationPermission !== 'granted' && showPushNotificationPrompt && (
                <div className="push-notification-prompt">
                  <p>{t('notifications.enable_notifications_prompt')}</p>
                  <div className="push-notification-prompt-actions">
                    <Button 
                      variant="primary" 
                      size="medium" 
                      onClick={() => window.location.reload()}
                    >
                      {t('notifications.enable_push_notifications')}
                    </Button>
                    <Button 
                      variant="secondary" 
                      size="medium" 
                      onClick={() => setShowPushNotificationPrompt(false)}
                    >
                      {t('notifications.maybe_later')}
                    </Button>
                  </div>
                </div>
              )}
            </Layout>
          </PostProvider>
        );
      case 'error':
        return <ErrorScreen message={t('profile.profile_load_error')} />
      default:
        return null;
    }
  };

  return (
    <ChatProvider>
      <Router>
        <div className="app-container">
          {renderContent()}
        </div>
      </Router>
    </ChatProvider>
  );
}

export default App;
// src/services/notificationService.js
import { app, db, auth } from '../firebase';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  limit, 
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
  deleteDoc
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ensureValidFCMToken } from './fcmTokenService';
import i18n from '../i18n';

// Add a debounce function to prevent rapid repeated calls
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const sendPushNotification = async (userId, title, body, data = {}) => {
  const functions = getFunctions(app);
  const sendNotification = httpsCallable(functions, 'sendPushNotification');
  
  try {
    console.log(`Sending push notification to user ${userId}:`, { title, body, data });
    const result = await sendNotification({ userId, title, body, data });
    console.log('Push notification sent successfully:', result.data);
    return result.data;
  } catch (error) {
    console.error('Error sending push notification:', error);
    if (error.code === 'functions/failed-precondition' && error.message.includes('Invalid FCM token')) {
      console.log('FCM token is invalid. Attempting to refresh...');
      await ensureValidFCMToken();
      // Retry sending the notification once
      return sendNotification({ userId, title, body, data });
    }
    throw error; // Re-throw the error for the caller to handle
  }
};

// Debounce the sendPushNotification function to prevent rapid repeated calls
const debouncedSendPushNotification = debounce(sendPushNotification, 1000);

export const createNotification = async (userId, type, relatedId, additionalData = {}) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to create a notification');

  try {
    console.log(`Creating notification for user ${userId}:`, { type, relatedId });
    
    // Check if a similar notification was created in the last minute
    const recentNotificationsQuery = query(
      collection(db, 'notifications'),
      where('userId', '==', userId),
      where('type', '==', type),
      where('relatedId', '==', relatedId),
      where('createdAt', '>', new Date(Date.now() - 60000)), // Last minute
      limit(1)
    );
    const recentNotifications = await getDocs(recentNotificationsQuery);
    
    if (!recentNotifications.empty) {
      console.log('Similar notification found within the last minute. Skipping creation.');
      return null;
    }

    // Fetch the current user's display name
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const userName = userDoc.data()?.displayName || 'A user';

    // Fetch recipient's language preference
    const recipientDoc = await getDoc(doc(db, 'users', userId));
    const userLanguage = recipientDoc.data()?.languagePreference || 'en';

    // Change the language before fetching translations
    await i18n.changeLanguage(userLanguage);

    let content = '';
    let title = '';
    switch (type) {
      case 'like':
        content = i18n.t('notifications.like', { userName, lng: userLanguage });
        title = i18n.t('notifications.like_title', { lng: userLanguage });
        break;
      case 'comment':
        content = i18n.t('notifications.comment', { userName, lng: userLanguage });
        title = i18n.t('notifications.comment_title', { lng: userLanguage });
        break;
      case 'share':
        content = i18n.t('notifications.share', { userName, lng: userLanguage });
        title = i18n.t('notifications.share_title', { lng: userLanguage });
        break;
      case 'reply':
        content = i18n.t('notifications.reply', { userName, lng: userLanguage });
        title = i18n.t('notifications.reply_title', { lng: userLanguage });
        break;
      case 'mention':
        content = i18n.t('notifications.mention', { userName, lng: userLanguage });
        title = i18n.t('notifications.mention_title', { lng: userLanguage });
        break;
      default:
        content = i18n.t('notifications.interaction', { userName, lng: userLanguage });
        title = i18n.t('notifications.interaction_title', { lng: userLanguage });
    }

    const notificationData = {
      userId,
      fromUserId: currentUser.uid,
      type,
      content,
      relatedId,
      createdAt: serverTimestamp(),
      read: false,
      ...additionalData, // Include any additional data
    };

    // Create the notification in Firestore
    const docRef = await addDoc(collection(db, 'notifications'), notificationData);
    console.log(`Notification created with ID: ${docRef.id}`);

    // Prepare push notification data
    const pushNotificationData = {
      notificationId: docRef.id,
      type,
      relatedId,
    };

    // Add commentIndex and replyIndex for comment, reply, and mention types
    if (['comment', 'reply', 'mention'].includes(type)) {
      if (additionalData.commentIndex !== undefined) {
        pushNotificationData.commentIndex = additionalData.commentIndex.toString();
      }
      if (additionalData.replyIndex !== undefined) {
        pushNotificationData.replyIndex = additionalData.replyIndex.toString();
      }
    }

    // Send push notification
    await debouncedSendPushNotification(userId, title, content, pushNotificationData);

    return { id: docRef.id, ...notificationData };
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const getNotifications = async (limitCount = 20) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to get notifications');

  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', currentUser.uid),
    orderBy('createdAt', 'desc'),
    limit(limitCount)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const markNotificationAsRead = async (notificationId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to update notifications');

  const notificationRef = doc(db, 'notifications', notificationId);
  await updateDoc(notificationRef, {
    read: true
  });
};

export const getUnreadNotificationsCount = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) return 0;

  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', currentUser.uid),
    where('read', '==', false)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
};

export const markAllNotificationsAsRead = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to update notifications');

  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', currentUser.uid),
    where('read', '==', false)
  );

  const querySnapshot = await getDocs(q);
  const batch = writeBatch(db);

  querySnapshot.forEach((docSnapshot) => {
    batch.update(doc(db, 'notifications', docSnapshot.id), { read: true });
  });

  await batch.commit();
};

export const deleteNotification = async (notificationId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to delete a notification');

  const notificationRef = doc(db, 'notifications', notificationId);
  const notificationSnap = await getDoc(notificationRef);

  if (!notificationSnap.exists()) {
    throw new Error('Notification not found');
  }

  if (notificationSnap.data().userId !== currentUser.uid) {
    throw new Error('User does not have permission to delete this notification');
  }

  await deleteDoc(notificationRef);
};

export const clearAllNotifications = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to clear notifications');

  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', currentUser.uid)
  );

  const querySnapshot = await getDocs(q);
  const batch = writeBatch(db);

  querySnapshot.forEach((docSnapshot) => {
    batch.delete(doc(db, 'notifications', docSnapshot.id));
  });

  await batch.commit();
};
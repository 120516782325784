// src/components/Header.js
import React, { useState, useEffect, useCallback } from 'react';
import { Bell, LogOut, Globe } from 'react-feather';
import NotificationsDropdown from './NotificationsDropdown';
import LanguageSwitcher from './LanguageSwitcher';
import { getUnreadNotificationsCount, markAllNotificationsAsRead } from '../services/notificationService';
import logo from '../assets/logo.png';
import './Header.css';

const Header = ({ onLogout }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const fetchUnreadCount = useCallback(async () => {
    const count = await getUnreadNotificationsCount();
    setUnreadCount(count);
  }, []);

  useEffect(() => {
    fetchUnreadCount();
    const timer = setInterval(fetchUnreadCount, 60000); // Check every minute

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchUnreadCount]);

  const handleNotificationClick = async () => {
    if (!isNotificationsOpen) {
      await markAllNotificationsAsRead();
      setUnreadCount(0);
    }
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <header className="app-header">
      <button onClick={handleRefresh} className="logo-button" aria-label="Refresh app">
        <img src={logo} alt="Affin Logo" className="logo" />
      </button>
      <div className="header-actions">
        <button 
          className="header-button notifications-button"
          onClick={handleNotificationClick}
          aria-label="Notifications"
        >
          <Bell size={24} />
          {unreadCount > 0 && (
            <span className="notification-badge">{unreadCount > 99 ? '99+' : unreadCount}</span>
          )}
        </button>
        <NotificationsDropdown 
          isOpen={isNotificationsOpen} 
          onClose={() => setIsNotificationsOpen(false)}
        />
        <div className="language-dropdown">
          <button 
            className="header-button language-button"
            onClick={toggleLanguageDropdown}
            aria-label="Change Language"
            aria-expanded={isLanguageOpen}
          >
            <Globe size={24} />
            {!isMobile && <span>Language</span>}
          </button>
          {isLanguageOpen && (
            <LanguageSwitcher onClose={() => setIsLanguageOpen(false)} />
          )}
        </div>
        <button 
          className="header-button logout-button"
          onClick={onLogout}
          aria-label="Logout"
        >
          <LogOut size={24} />
          {!isMobile && <span>Logout</span>}
        </button>
      </div>
    </header>
  );
};

export default Header;
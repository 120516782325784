// src/pages/Profile.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InstantSearch, Configure } from 'react-instantsearch';
import searchClient, { ALGOLIA_INDEX_NAME } from '../config/algoliaConfig';
import ProfileForm from '../components/ProfileForm';
import PostList from '../components/PostList';
import SearchBar from '../components/SearchBar';
import Button from '../components/Button';
import { PostProvider } from '../contexts/PostContext';
import { usePost } from '../hooks/usePost';
import useUserProfile from '../hooks/useUserProfile';
import useUserPosts from '../hooks/useUserPosts';
import '../styles/Profile.css';

const Profile = ({ initialTab = 'posts' }) => {
  const { t } = useTranslation();
  const { userId: paramUserId } = useParams();
  const { auth } = require('../firebase');
  const currentUser = auth.currentUser;
  const profileUserId = paramUserId || (currentUser && currentUser.uid);

  const { profile, isCurrentUser, loadingProfile, profileError, setProfile } = useUserProfile(profileUserId);
  const {
    userPosts,
    savedPosts,
    savedPostIds,
    loadingPosts,
    postsError,
    setUserPosts,
  } = useUserPosts(profileUserId);

  const [editing, setEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [searchState, setSearchState] = useState({});

  const { setPosts } = usePost();

  const handleSearchStateChange = (nextSearchState) => {
    setSearchState(nextSearchState);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(updatedProfile);
    setEditing(false);
  };

  const handlePostDelete = (deletedPostId) => {
    setUserPosts((currentPosts) => currentPosts.filter((post) => post.id !== deletedPostId));
    setPosts((currentPosts) => currentPosts.filter((post) => post.id !== deletedPostId));
  };

  if (loadingProfile || loadingPosts) {
    return <div className="loading">{t('loading')}</div>;
  }

  if (profileError || postsError) {
    return (
      <div className="error-message">
        {t('error')}: {profileError || postsError}
      </div>
    );
  }

  if (!profile) {
    return <div className="error-message">{t('profile.not_found')}</div>;
  }

  const filterString =
    activeTab === 'saved'
      ? `objectID:${savedPostIds.join(' OR objectID:')}`
      : `userId:${profileUserId}`;

  return (
    <PostProvider>
      <div className="profile-container">
        <div className="profile-header">
          {editing ? (
            <ProfileForm
              existingProfile={profile}
              onProfileUpdate={handleProfileUpdate}
              onCancel={handleCancelEdit}
            />
          ) : (
            <>
              {profile.profilePictureUrl ? (
                <img
                  src={profile.profilePictureUrl}
                  alt={t('profile.profile_picture_alt', { name: profile.displayName })}
                  className="profile-picture"
                />
              ) : (
                <div className="default-avatar">
                  {/* Default avatar icon or image */}
                </div>
              )}
              <h2 className="profile-name">{profile.displayName}</h2>
              <p className="profile-username">@{profile.username}</p>
              <p className="profile-bio">{profile.bio}</p>
              {isCurrentUser && (
                <Button
                  onClick={handleEditProfile}
                  variant="primary"
                  size="medium"
                  className="profile-edit-button"
                >
                  {t('profile.edit_profile')}
                </Button>
              )}
            </>
          )}
        </div>
        <div className="profile-tabs">
          <button
            className={`profile-tab ${activeTab === 'posts' ? 'active' : ''}`}
            onClick={() => handleTabChange('posts')}
          >
            {t('posts.title')}
          </button>
          {isCurrentUser && (
            <button
              className={`profile-tab ${activeTab === 'saved' ? 'active' : ''}`}
              onClick={() => handleTabChange('saved')}
            >
              {t('profile.saved_posts')}
            </button>
          )}
        </div>
        <InstantSearch
          searchClient={searchClient}
          indexName={ALGOLIA_INDEX_NAME}
          searchState={searchState}
          onSearchStateChange={handleSearchStateChange}
        >
          <Configure filters={filterString} />
          <div className="search-container">
            <SearchBar />
          </div>
          <div className="profile-content">
            <PostList
              defaultPosts={activeTab === 'posts' ? userPosts : savedPosts}
              isCurrentUser={isCurrentUser}
              activeTab={activeTab}
              onPostDeleted={handlePostDelete}
            />
          </div>
        </InstantSearch>
      </div>
    </PostProvider>
  );
};

export default Profile;